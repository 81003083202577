* {
	padding: 0;
	margin: 0;
}

body {
	font: 14px open_sansregular, Helvetica Neue, Helvetica, Arial, sans-serif;
	color: #313b4e;
	min-height: 100%;
	height: 100%;
}

#app, html {
	height: 100%;
	width: 100%;
	min-width: 360px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

#app {
	margin: 0 auto;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-4px);
	}
	60% {
		transform: translateY(-2px);
	}
}

hr {
	border: none;
	border-top: 1px solid #cccbcb;
	margin: 0 20px;
	pointer-events: none;
}

.hoverActive {
	transition: 0.1s background-color ease-in;
	cursor: pointer;
}

.hoverActive:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

@media print {
	.printFullWidth {
		flex: 1
	}
}

@media print {
	.printNone {
		display: none;
	}
}

@font-face {
	font-family: 'open_sansregular';
	src: url('./opensans-regular.woff2') format('woff2'), url('./opensans-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'partnertap-icons';
	src: url('./partnertapsymbols1-normal-webfont.woff2') format('woff2'), url('./partnertapsymbols1-normal-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

.partnertap-icons {
	font-family: partnertap-icons, open_sansregular, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	vertical-align: middle;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

.MyCustomElement {
	position: relative;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100vh;
}
